<template>
    <main id="main">
        <section id="blog" class="blog" style="margin-top:100px;">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="col-12 shadow rounded-4 p-5">
                        <h2 class="fw-bold text-center txt-system m-0">Registered Successfully</h2>
                        <p class="my-5" style="text-align: justify;">
                            Thank you for registering with Cambodia Businesspreneur Council. 
                            <br><br> 
                            Please check your email to verify your email and login. Once you have successfully logged in, please go to Membership Profile tab to the Documents section and upload necessary documents. Below are the required documents:
                            <br>
                            <ul>
                                <li>Current Certificate of Registration with Ministry of Commerce (Scanned Copy)</li>
                                <li>Patent Tax Certificate (Scanned Copy)</li>
                                <li>Name and contact of CEO and additional representative</li>
                                <li>Company LOGO</li>
                            </ul>
                        </p>
                        <div class="text-center">
                            <router-link class="btn btn-outline-primary" to="/login"><p class="m-0">Click here to login</p></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </template>
    
    <script>
    import axios from 'axios';
    import { sideBar } from '@/networks/NewsPage/news.service'
    import { resource } from '@/networks/domain';
    
    export default {
        // props : ["data"],
        data(){
            return {
                articles : [],
                videos : [],
                resource : resource,
                isTrue : true
            }
        },
        methods : {
            checkProps(){
                if(this.$route.params.data != "success"){
                    if(this.$router.back.length > 0){
                        this.$router.go(-1)
                    } else {
                        this.$router.push('/');
                    }
                }
            }
        },
        mounted(){
            // this.checkProps();
            $('html, body').animate({
                scrollTop: $("#main").offset().top - 100
            }, 20);
        }
    }
    </script>
    
    <style>
    
    </style>
    